// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("./../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-aviso-legal-js": () => import("./../src/pages/aviso-legal.js" /* webpackChunkName: "component---src-pages-aviso-legal-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-politica-cookies-js": () => import("./../src/pages/politica-cookies.js" /* webpackChunkName: "component---src-pages-politica-cookies-js" */),
  "component---src-pages-politica-privacidad-js": () => import("./../src/pages/politica-privacidad.js" /* webpackChunkName: "component---src-pages-politica-privacidad-js" */),
  "component---src-pages-productos-js": () => import("./../src/pages/productos.js" /* webpackChunkName: "component---src-pages-productos-js" */)
}

